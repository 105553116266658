<template>
	<div id="ShopDetail" @mouseup="mouseupScroll">
		<Navigation />
		<ClassifyChoose></ClassifyChoose>
		<SideNavigation></SideNavigation>
		<div class="w" style="position: relative;">
			<Breadcrumb style="margin-left: 10px;" :navigationList="navigationList"></Breadcrumb>
			<div class="S-kefu">
				<div class="S-kefu-title">
					<!-- @mouseenter=" moves" @mouseout="downs" -->
					所在地：{{shopAddress1}} <!-- {{shopDetail.shopName}} -->
				</div>
				<div class="S-kefu-title" style="margin-left: 30px;cursor: pointer;" @click="dianpu">
					<!-- @mouseenter=" moves" @mouseout="downs" -->
					点击进入该店铺
				</div>
				<div style="display: flex;cursor: pointer;" @click="clickkefu">
					<img src="../../assets/img/kefu.png" style="width: 25px;height: 25px;margin-left: 16px;">
					<div class="S-texts">
						联系客服
					</div>
				</div>
				<div class="dianpu" v-if="sAshow">
					<!-- 店铺地址： -->

				</div>
			</div>
		</div>

		<el-dialog title="我要反馈" :visible.sync="dialogFormVisible" :modal="false">
			<el-form :model="form">
				<el-form-item label="反馈内容">
					<el-input v-model="form.content" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="姓名">
					<el-input v-model="form.username" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="手机号码">
					<el-input v-model="form.phone" autocomplete="off"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogFormVisible = false">取 消</el-button>
				<el-button type="primary" style="background-color: #D80000; border: none;" @click="goodsreport">确 定
				</el-button>
			</div>
		</el-dialog>

		<div class="detail">
			<!-- 左 -->
			<div class="detail-l">
				<!-- shopDetail.cover -->
				<!-- <el-image class="detail-l-pic" :src="bannerList[indexs]" :fit="'cover'">
				</el-image> -->
				<el-carousel height="456px" indicator-position="always" arrow="never">
					<el-carousel-item class="detail-l-pic" v-for="(item,index) in bannerList" :key="index">
						<el-image v-if="item" class="detail-l-pic" :src="item" :fit="'cover'">
						</el-image>
						<!-- <img :src="item.url" alt=""> -->
					</el-carousel-item>
				</el-carousel>
				<div class="detail-l-scroll flcs">
					<img class="detail-l-scroll-l" src="../../assets/img/left1.png">
					<div class="detail-l-scroll-c">
						<div class="detail-l-scroll-c-box" ref="zxScroll" @mousedown="mousedownScroll"
							@mousemove="mousemoveScroll">
							<div class="" v-for="(item,index) in bannerList" :key="index" @click.stop="picCkick(index)">
								<!-- <el-image class="detail-l-scroll-c-pic"
									src="https://t7.baidu.com/it/u=1595072465,3644073269&fm=193&f=GIF" :fit="'cover'">
								</el-image> -->
								<img class="detail-l-scroll-c-pic"
									:style="{marginRight:bannerList.length-1 == index ? '0px' : '20px'}" :src="item">
							</div>
						</div>
					</div>
					<img class="detail-l-scroll-r" src="../../assets/img/right1.png">
				</div>

				<div class="detail-l-b">
					<div class="detail-l-b-l cursor" @click="goodscollection">
						<img class="detail-l-b-l-i"
							:src=" shopDetail.isCollection == 1 ? require('../../assets/img/collect2.png') : require('../../assets/img/collect1.png') ">
						<div class="detail-l-b-l-r">
							收藏商品
						</div>
					</div>

					<div class="detail-l-b-r" @click="dialogFormVisible = true">
						商品反馈
					</div>
				</div>
			</div>



			<!-- 右 -->
			<div class="detail-r">
				<div class="detail-r-title">
					{{shopDetail.title}}
				</div>
				<div class="detail-r-price">
					<span
						v-if="JSON.stringify(shopDetail) != '{}' && shopDetail.goodsItems.length != 0">¥{{shopDetail.goodsItems[fromData.itemIndex].price}}</span>
					<span v-else>暂无规格</span>
				</div>
				<div class="detail-r-classify" v-if="CouponList.length>=1">
					<div class="detail-r-classify-l">
						优惠券
					</div>
					<div class="detail-r-classify-r flw">
						<div :class="Couponid == item.id ? 'detail-r-classify-r-a' :'detail-r-classify-r-i' " style="margin-bottom: 20px;"
							v-for=" (item,index) in CouponList" :key="index" @click="CouponClick(item.id)">
							满{{item.minPrice}}减{{item.price}}
						</div>
					</div>
				</div>
				<div class="detail-r-classify" style="margin-top: 30px;">
					<div class="detail-r-classify-l">
						颜色
					</div>
					<div class="detail-r-classify-r flw">
						<div :class="fromData.itemIndex == index ? 'detail-r-classify-r-a' :'detail-r-classify-r-i' "
							v-for=" (item,index) in shopDetail.goodsItems" :key="index" @click="itemClick(index,item)">
							{{item.title}}
						</div>
					</div>
				</div>

				<div class="detail-r-way">
					<div class="detail-r-way-l">
						支付方式
					</div>
					<div class="detail-r-way-r flw">
						<div class="detail-r-way-r-i flc">
							<img class="detail-r-way-r-i-l" src="../../assets/img/wx.png">
							<div class="detail-r-way-r-i-r">
								微信
							</div>
						</div>
						<!-- <div class="detail-r-way-r-i flc">
							<img class="detail-r-way-r-i-l" src="../../assets/img/zfb.png">
							<div class="detail-r-way-r-i-r">
								支付宝
							</div>
						</div> -->
					</div>
				</div>

				<div class="detail-r-num">
					<div class="detail-r-num-l">
						数量
					</div>
					<div class="detail-r-num-r flc">
						<div class="detail-r-num-r-l cursor" @click="subClick">
							-
						</div>
						<div class="detail-r-num-r-c">
							{{fromData.num}}
						</div>
						<div class="detail-r-num-r-l cursor" @click="addClick">
							+
						</div>
					</div>
				</div>

				<div class="detail-r-num">
					<div class="detail-r-num-l">
						运费
					</div>
					<div style="display: flex;align-items: center;">
						<div style="font-size: 15px;">
							{{shopAddress[0]}}市
						</div>
						<div style="font-size: 15px;margin: 0px 30px;">
							至
						</div>
						<div style="font-size: 15px;">
							{{userAddress}}
						</div>
						<div style="font-size: 15px;margin-left: 30px;">
							快递：{{postage}}
						</div>
					</div>

				</div>
				
				<div class="detail-r-classify" v-if="shopDetail.isShowPhone==1" style="margin-top: 30px;">
					<div class="detail-r-classify-l">
						商家电话：
					</div>
					<div class="detail-r-classify-l">
						{{shopDetail.shopPhone}}
					</div>
				</div>
				<div class="detail-r-pay flc">
					<div class="text-none" @click="orderaddShopping">
						<div class="detail-r-pay-l cursor">
							加入购物车
						</div>
					</div>
					<!-- <el-button class="detail-r-pay-l text-none" :plain="true" @click="orderaddShopping" >加入购物车
					</el-button> -->
					<div class="text-none cursor" @click="orderbuyNow">
						<div class="detail-r-pay-r">
							立即购买
						</div>
					</div>
				</div>

			</div>
		</div>
		<!-- <div class="detail-evaluate">
			<div class="detail-evaluate-top flc"> -->
		<!-- <div :class="isDetail ?  'detail-evaluate-top-l' : 'detail-evaluate-top-r'" @click="isDetail = true"> -->
		<!-- <div class="detail-evaluate-top-l">	
				店长推荐
				</div> -->
		<!-- <div :class="!isDetail ?  'detail-evaluate-top-l' : 'detail-evaluate-top-r'" @click="isDetail = false">
					商品评价({{comments.length}})
				</div> -->

		<!-- </div>
			<div class="" style="display: flex;" >
				<div v-for="item in 6" class="stores"
				>
					<img src="../../assets/img/1.jpg" style="width: 160px;height: 160px;border-radius: 5px  5px 0 0;" >
					<div id="" style="color: #D80000;margin: 10px 0;font-size: 23px;">
						￥999
					</div>
				</div>
			</div>
		</div> -->

		<div class="detail-evaluate">
			<div class="detail-evaluate-top flc">
				<div :class="isDetail ?  'detail-evaluate-top-l' : 'detail-evaluate-top-r'" @click="isDetail = true">
					商品详情
				</div>
				<div :class="!isDetail ?  'detail-evaluate-top-l' : 'detail-evaluate-top-r'" @click="isDetail = false">
					商品评价({{comments.length}})
				</div>
			</div>
			<div class="detail-evaluate-d" v-html="shopDetail.content" v-if="isDetail">

			</div>
			<div class="evaluate-box" v-else>
				<div class="evaluate-box-i" v-for="(item,index) in comments" :key="index">
					<el-image class="evaluate-box-i-l" v-if="item.headImg" :src="item.headImg" :fit="'cover'">
					</el-image>
					<img class="evaluate-box-i-l" v-else src="../../assets/img/person.png" :fit="'cover'">
					</img>
					<div class="evaluate-box-i-r">
						<div class="evaluate-box-i-r-t">
							{{item.username}}
						</div>
						<div class="evaluate-box-i-r-rext">
							{{item.content}}
						</div>
						<div class="evaluate-box-i-r-pic flw" v-if="item.images != ''">
							<el-image class="evaluate-box-i-r-pic-i" v-for="(item2,index2) in item.images.split(',')"
								:key="index2" :src="item2" :fit="'cover'">
							</el-image>
						</div>
						<div class="evaluate-box-i-r-b">
							{{item.created}}
						</div>
					</div>

				</div>
			</div>
		</div>

		<Footer></Footer>

		<kefu v-if="shows"></kefu>
	</div>
</template>

<script>
	import Navigation from "../common/navigation.vue"
	import ClassifyChoose from "../common/classify_choose.vue"
	import Breadcrumb from "../common/breadcrumb.vue"
	import SideNavigation from "../common/side_navigation.vue"
	import Footer from "../common/footer.vue"
	import kefu from "../common/kefu.vue"

	import {
		goodsinfo,
		goodscollection,
		orderaddShopping,
		orderbuyNow,
		consumerAddressInfo,
		goodsreport,
		goodsFeedback,
		goodsGetCoupons,//领取优惠券
		goodsCouponList,//店铺优惠券列表
	} from "../../utils/index.js"

	export default {
		name: "ShopDetail",
		components: {
			Navigation,
			ClassifyChoose,
			Breadcrumb,
			SideNavigation,
			Footer,
			kefu
		},
		data() {
			return {
				isScroll: false,
				scrollScreenX: 0,
				bannerList: [],
				bannerIndex: 0,
				isDetail: true,
				shopId: "",
				shopDetail: {},
				comments: [],
				// 加入购物车
				fromData: {
					itemId: "",
					itemIndex: 0,
					num: 1
				},
				navigationList: [{
					name: "首页",
					url: "/",
					type: 0
				}],
				uid: "",
				indexs: 0,
				shows: false,
				//店铺地址
				shopAddress: '',
				shopAddress1: '',
				sAshow: false,
				//运费
				postage: '',
				userAddress: '', //用户地址
				dialogFormVisible: false,
				form: {
					content: '',
					username:'',
					phone:'',
					id: "",
				},
				CouponList:[],//店铺优惠券列表
				Couponid:0,
			}
		},
		created() {
			this.shopId = this.$route.query.id
			this.form.id = this.shopId
			this.goodsinfo()
			
			this.uid = localStorage.getItem('uid')
			
			// localStorage.setItem('uid', 5)
		},
		methods: {
			goodsreport() {
				if(this.form.username==''){
					this.$message({
						message: '请输入姓名',
						type: 'warning'
					});
					
				}else if(this.form.phone==''){
					this.$message({
						message: '请输入手机号',
						type: 'warning'
					});
				}else if (!(/^1[3456789]\d{9}$/.test(this.form.phone))) {
					this.$message({
						message: '请输入正确的手机号',
						type: 'error'
					});
					this.form.phone=''
				} else if(this.form.content==''){
          this.$message({
            message: '内容不能为空',
            type: 'warning'
          });
        }else{
					goodsFeedback(this.form).then(res=>{
						//console.log("反馈",res)
						if(res.code==200){
							this.form.username=''
							this.form.phone=''
							this.form.content=''
							this.$message({
								message: '提交成功',
								type: 'success'
							});
							setTimeout(()=>{
								this.dialogFormVisible = false
							},1000)
						}
					})
					
					
					
				}
				
			},
			dianpu() {
				this.$router.push({
					name: 'stores_home',
					query: {
						AD: this.shopDetail.shopId
					}
				})
			},
			//店铺地址显示
			downs() {
				this.sAshow = false
			},
			moves() {
				this.sAshow = true
			},

			// 是否登录
			islogin() {
				if (this.uid == "") return true
				return false
			},

			// 立即购买
			orderbuyNow() {
				if (this.fromData.itemId == "") {
					this.$message({
						message: "暂无规格",
						type: 'warning'
					});
					return
				}

				let uid = localStorage.getItem('uid')
				//if (this.islogin()) return
				if (uid !== null) {

					orderbuyNow(this.fromData).then(res => {
						//console.log("立即购买", res);
						if (res.code == 500) {
							this.$message({
								message: res.message,
								type: 'warning'
							});
						} else {
							this.$router.push({
								name: 'shop_pay',
								query: {
									cc: res.data
								}
							})
						}

					})
				} else {
					this.$router.push({
						path: '/login'
					})
				}
			},

			// 加入购物车
			orderaddShopping() {
				if (this.fromData.itemId == "") {
					this.$message({
						message: "暂无规格",
						type: 'warning'
					});
					return
				}

				let uid = localStorage.getItem('uid')
				//if (this.islogin()) return
				if (uid !== null) {
					orderaddShopping(this.fromData).then(res => {
						//console.log("加入购物车", res);
						if (res.code == 500) {
							this.$message({
								message: res.message,
								type: 'warning'
							});
						} else {
							this.$message({
								message: '加入购物车成功',
								type: 'success'
							});
						}
						
					})
				} else {
					this.$router.push({
						path: '/login'
					})
				}

			},

			// 商品详情
			goodsinfo() {
				goodsinfo({
					id: this.shopId
				}).then(res => {
					 //console.log("商品详情", res);
					this.shopDetail = res.data
					this.shopDetail.content=res.data.content.replace(/\<img/gi, '<img style="width:100%;height:auto"');
					this.comments = res.data.comments
					this.postage = res.data.postage
					this.userAddress = res.data.userAddress
					let arr = this.shopDetail.banner.split(',')
					this.bannerList = arr.length == 1 ? arr : arr.splice(0, arr.length - 1)
					//console.log("商品详情",res);
					this.navigationList.push({
						name: this.shopDetail.title,
						url: "",
						type: 2
					})
					this.shopAddress1 = res.data.shopAddress
					this.shopAddress = res.data.shopAddress.split('市')
					//console.log("商品详情",shopAddress);
					if (this.shopDetail.goodsItems.length != 0) {
						this.fromData.itemId = this.shopDetail.goodsItems[0].id
					}
					this.goodsCouponList()
				})
			},
			// 商品收藏
			goodscollection() {
				if (this.islogin()) return

				goodscollection({
					goodsId: this.shopId
				}).then(res => {
					//console.log("商品收藏", res);
					this.shopDetail.isCollection = this.shopDetail.isCollection == 1 ? 0 : 1
				})
			},

			picCkick(index) {
				//console.log(index);
				this.indexs = index
				// this.$refs.zxScroll.scrollLeft = 111
			},
			mousedownScroll() {
				this.isScroll = true
			},
			mousemoveScroll(e) {
				if (this.isScroll) {
					if (this.scrollScreenX < e.screenX) {
						if (this.scrollScreenX != 0 && (this.bannerList.length * 100 - 20) > this.$refs.zxScroll
							.scrollLeft) {
							this.$refs.zxScroll.scrollLeft += e.screenX - this.scrollScreenX
						}
					} else {
						if (this.scrollScreenX != 0 && 0 < this.$refs.zxScroll
							.scrollLeft) {
							this.$refs.zxScroll.scrollLeft -= this.scrollScreenX - e.screenX
						}
					}
					this.scrollScreenX = e.screenX
				}
			},
			mouseupScroll() {
				this.isScroll = false
			},
			subClick() {
				if (this.fromData.num > 1) {
					this.fromData.num--
				}
			},
			addClick() {
				this.fromData.num++
			},
			CouponClick(index, item) {
				this.Couponid = index
				this.goodsGetCoupons(index)
				//this.fromData.itemId = item.id
			},
			itemClick(index, item) {
				this.fromData.itemIndex = index
				this.fromData.itemId = item.id
			},
			clickkefu() {
				
				let uid = localStorage.getItem('uid')
				//if (this.islogin()) return
				if (uid !== null) {
				
					const routeData = this.$router.resolve({
						name: 'Chat',
						query: {
							u: this.shopDetail.shopId
						}
					})
					window.open(routeData.href, '_blank')
				} else {
					this.$router.push({
						path: '/login'
					})
				}
				
				
			},
			goodsCouponList(){
				
				goodsCouponList({
					shopId:this.shopDetail.shopId
				}).then(res=>{
					//console.log("优惠券列表",res)
					this.CouponList=res.data
				})
			},
			goodsGetCoupons(e){
				goodsGetCoupons({
					couponId:e,
				}).then(res=>{
					//console.log("领取优惠券",res)
					if (res.code == 500) {
						this.$message({
							message: res.message,
							type: 'warning'
						});
					} else {
						this.$message({
							message: '领取成功',
							type: 'success'
						});
					}
				})
			}
		}
	}
</script>

<style lang="less">
	@import url("shop_detail.less");

	.el-carousel__item h3 {
		color: #475669;
		font-size: 18px;
		opacity: 0.75;
		line-height: 300px;
		margin: 0;

	}

	.el-carousel__item:nth-child(2n) {
		background-color: #99a9bf;
	}

	.el-carousel__item:nth-child(2n+1) {
		background-color: #d3dce6;
	}

	.w {
		width: 1200px;
		margin: 0 auto;
		background: #F8F8F8;
	}

	.S-kefu {
		position: absolute;
		right: 0;
		top: 20px;
		display: flex;

		.S-kefu-title {
			font-size: 14px;
			font-family: PingFang SC;
			font-weight: bold;
			color: #333333;
		}

		.S-texts {
			margin-left: 10px;
			margin-right: 30px;
			font-size: 14px;
			font-family: PingFang SC;
			font-weight: 500;
			color: #D80000;
		}

		.dianpu {
			position: absolute;
			top: 30px;
			left: -300px;
			background-color: #ffffff;
			padding: 30px;
			border-radius: 10px;
			box-shadow: 0px 0px 8px 5px #3333331f;
		}
	}

	.stores {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 35px;
		margin-left: 30px;
		border: 1px solid rgba(0, 0, 0, .09);
		border-radius: 5px;
	}

	.stores:hover {
		box-shadow: 0 -3px 10px 9px rgba(0, 0, 0, .09);
		border-radius: 10px;
	}
</style>
